// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-series-js": () => import("./../../../src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */),
  "component---src-templates-episode-js": () => import("./../../../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-show-js": () => import("./../../../src/templates/show.js" /* webpackChunkName: "component---src-templates-show-js" */)
}

